import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Typefaces from "../components/typefaces"

import { Row, Col, Image } from "react-bootstrap"

//images imports
import aboutBeauty2 from "./../images/about beauty, risograph/about beauty, risograph1.jpg"
import aboutBeauty3 from "./../images/about beauty, risograph/about beauty, risograph2.jpg"
import aboutBeauty4 from "./../images/about beauty, risograph/about beauty, risograph3.jpg"
import aboutBeauty1 from "./../images/about beauty, risograph/fp-about beauty, risograph.jpg"

const AboutBeauty = ({location, id}) => (
    <Layout>
        <Seo title="About Beauty, risograph" description="About Beauty, risograph. By Manon Lambeens" />
            <Row className="">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
              <h1 className="text-uppercase fs-4 text-dark">about beauty</h1>
                <p className="textAbout">
                About beauty is a handmade riso-printed booklet. The booklet has the size of a postcard. It is a personal text and is meant for people to carry it around.  It is small so that you can put it in your purse of even backpocket.   
                </p>
                <Typefaces types={['Times']} />
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={aboutBeauty2} fluid style={{width: '100%'}} alt="About Beauty, risograph, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={aboutBeauty3} fluid alt="About Beauty, risograph, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={aboutBeauty4} fluid alt="About Beauty, risograph, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={aboutBeauty1} fluid style={{width: '100%'}} alt="About Beauty, risograph, Manon Lambeens"/>
        </Col>
      </Row>
    </Layout>
)

export default AboutBeauty;